<template>
  <div class="whole">
		<div class="notfund">
      <img class="img" src="@/assets/imgs/404.jpg" />
      <div class="box">
        <p>抱歉，您访问的页面找不到了/(ㄒoㄒ)/~~</p>
        <el-button type="primary" @click="$router.back()">返回上一页</el-button>
      </div>
    </div>
  </div>
</template>

<script>
  import { useRouter } from 'vue-router'

  export default {
		setup() {
      const router = useRouter()
      function toBackPage() {

      }

      return {
        toBackPage
      }
    }
  }
</script>

<style lang="scss" scoped>
  .whole{
    width: 100%;
    height: 100vh;
    .notfund{
      width: 100%;
      position: relative;
      text-align: center;
    }
    .box{
      position: absolute;
      width: 100%;
      bottom: 0;
    }
  }
</style>
